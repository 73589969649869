/* globals nx */
import {Swipable} from './Swipable.js';
import {BasicCarousel} from  './BasicCarousel.js';
import {getCardWidth} from '../ute/utils.js';

// Recommended and Products in this range, also inserted merch in search and browse, and homepage
var ProductCarousel = function ProductCarousel(wrapper) {
    this.wrapper = wrapper;
    this.middle = wrapper.querySelector('.middle');
    this.inner = wrapper.querySelector('.inner');
    this.cards = this.inner.querySelectorAll('.griditem, .itemcard');
    if(this.cards.length == 0) return this;
    this.numCards = this.cards.length;
    this.navLeftClass = 'carousel-control prev';
    this.navRightClass = 'carousel-control next';
    this.init(wrapper);
    this.setupNav();
    new Swipable(
        this.slideNext.bind(this),
        this.slidePrev.bind(this),
        this.resetScroll.bind(this),
        this.animateScroll.bind(this),
        this.inner,
        30
    );

    // if the page resizes, we need to redo the carousel
    nx.ute.resizeManager.register(this, 2); // Needs to move a minimum of 2 pixels

    return this;
}
ProductCarousel.prototype = new BasicCarousel();
ProductCarousel.prototype.constructor = ProductCarousel;
ProductCarousel.prototype.init = function init(wrapper) {
    if (this.inner.classList.contains('itemcard_container')) { 
        return this.initItemcard(wrapper); // post itemcard experiment, just replace this init function
    }
    var itemWidth = getCardWidth(this.cards[0]);
    var maxWidth = wrapper.clientWidth;
    this.imagesPerPane = Math.floor(maxWidth / itemWidth);
    this.PANE_WIDTH = (this.imagesPerPane * itemWidth);
    this.middle.style.width = this.PANE_WIDTH + 'px';
    this.curPos = 0;
    this.numPanes = Math.ceil(this.numCards / this.imagesPerPane);
    this.inner.style.width = (this.numCards * itemWidth) + 'px';
    this.inner.style.marginLeft = '-4px'
    this.buffer = 4; //margin to help with box shadows
    return this;
}
ProductCarousel.prototype.initItemcard = function initItemcard(wrapper) {
    const GAP = 12;
    const minCardWidth = 183 + GAP;
    const maxWidth = wrapper.clientWidth;
    this.imagesPerPane = Math.floor((maxWidth + GAP) / minCardWidth);
    const numGaps = this.imagesPerPane - 1;
    const gapDistance = GAP * numGaps;
    const desiredCardWidth = (maxWidth - gapDistance) / this.imagesPerPane;
    this.inner.style.width = ((this.numCards * desiredCardWidth) + ((this.numCards - 1) * GAP)) + 'px';
    this.PANE_WIDTH = maxWidth;
    this.middle.style.width = this.PANE_WIDTH + 'px';
    this.numPanes = Math.ceil(this.numCards / this.imagesPerPane);
    this.inner.style.gridTemplateColumns = `repeat(${this.numCards},minmax(182px,1fr))`;
    this.gap = GAP;
    this.curPos = 0;
}

ProductCarousel.prototype.resize = function resize() {
    const wrapper = this.wrapper;

    wrapper.productCarousel.init(wrapper);
    wrapper.productCarousel.slideTo(0);
};

export {ProductCarousel};
