import { readDatalayer, addClassName, removeClassName } from '../ute/utils';

// browserDetect Mapper
const browserDetectMap = {
  ie: 'MSIE',
  ie11: 'Trident',
  edge: 'Edge',
  chrome: 'Chrome',
  firefox: 'Firefox',
  safari: 'Safari',
  opera: 'OPR'
};

// cycleNotificationRibbons start
const showNextRibbon = (current, ribbonsToShow) => {
  var next = current + 1;
  if (next >= ribbonsToShow.length) {
    next = 0;
  }
  removeClassName(ribbonsToShow[current], 'notification-ribbon-visible');
  addClassName(ribbonsToShow[next], 'notification-ribbon-visible');
  setTimeout(showNextRibbon, 5000, next, ribbonsToShow);
}

const cycleNotificationRibbons = () => {
  var ribbonContainer = document.querySelector('.notification-ribbons');
  if (!ribbonContainer) return false;
  if (screen.width < 481) {
    /* $phone-lg breakpoint */
    ribbonContainer.style.display = 'none';
    return false;
  }
  var ribbons = Array.prototype.slice.call(
    ribbonContainer.querySelectorAll('.notification-ribbon') || []
  );
  var userProfiles = Object.keys(readDatalayer(['global', 'profiles']) || {});
  var userChannel = readDatalayer(['global', 'channel']);
  var ribbonsToShow = ribbons.reduce(function (acc, ribbon) {
    var channel = ribbon.getAttribute('data-channel') || userChannel;
    var profile = ribbon.getAttribute('data-profile') || userProfiles[0];
    var browserdetect = ribbon.getAttribute('data-browserdetect');
    if (!browserdetect && userChannel == channel && userProfiles.indexOf(profile) > -1) {
      ribbon.innerHTML =
        `<div class="notification-ribbon-inner">${ribbon.innerHTML}</div>`;
      acc.push(ribbon);
    }
    if (browserdetect) {
      const browserDetectAgentStr = browserDetectMap[browserdetect];
      if (!browserDetectAgentStr) {
        console.warn('Invalid browserdetect value', browserdetect); // eslint-disable-line no-console
        return acc;
      }

      if (navigator.userAgent.indexOf(browserDetectAgentStr) > -1) {
          ribbon.innerHTML =
            `<div class="notification-ribbon-inner">${ribbon.innerHTML}</div>`;
          acc.push(ribbon);
      }
    }
    return acc;
  }, []);
  if (!ribbonsToShow.length) {
    ribbonContainer.remove();
    return false;
  }
  ribbonContainer.style.display = 'block';
  addClassName(ribbonsToShow[0], 'notification-ribbon-visible');
  if (ribbonsToShow.length > 1) {
    setTimeout(showNextRibbon, 5000, 0, ribbonsToShow);
  }
  return true;
}

export {
  cycleNotificationRibbons
};
